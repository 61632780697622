import { ReactNode, useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useDeviceDetect } from '@wbk/hooks';
import { useAuth } from '@wbk/auth';
import CircleSpinner from '@/components/ui/spinners/CircleSpinner';
import { getSafeRedirectRoute } from '@/util/getSafeRedirectRoute';

interface Props {
  children: ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  const { pathname, replace, query } = useRouter();
  const { user, isNewUser, loading } = useAuth({ autoFetch: true });
  const isValidUser = !!user && !isNewUser;
  const keepLoading = loading || isValidUser;
  const { isWebView } = useDeviceDetect();

  useEffect(() => {
    if (isValidUser) {
      if (query.redirect) {
        const dest = getSafeRedirectRoute(query.redirect as string);
        if (dest.startsWith('http')) {
          window.location.href = dest;
        } else {
          replace(dest);
        }
      } else if (pathname.includes('/login') || pathname.includes('/signup')) {
        replace('/');
      }
    }
  }, [isValidUser, pathname, replace, query]);

  return (
    <section
      data-webview={!!isWebView}
      className='relative flex min-h-[95vh] items-center after:absolute after:inset-0 after:bg-black/80 data-[webview=false]:before:absolute data-[webview=false]:before:inset-0 data-[webview=false]:before:z-[-1] data-[webview=false]:before:bg-[url(/images/default-cover.jpg)] data-[webview=false]:before:bg-cover data-[webview=false]:before:bg-no-repeat'
    >
      <div className='container relative z-10 mx-auto grid grid-cols-1 py-10 lg:grid-cols-2'>
        {!isWebView && (
          <div className='hidden items-center pb-8 md:flex'>
            <Image
              className='mx-auto h-28 object-contain'
              src='/icons/logo.png'
              height={112}
              width={112}
              alt='Logo'
            />
          </div>
        )}

        <div className='mx-auto w-full max-w-lg'>
          {keepLoading ? (
            <div className='flex h-80 max-w-lg items-center justify-center overflow-hidden rounded-lg bg-body-level-1 p-4 text-primary lg:max-w-full'>
              <CircleSpinner width={100} />
            </div>
          ) : (
            children
          )}
        </div>
      </div>
    </section>
  );
};

export default AuthLayout;
